import React from "react";
import { graphql } from "gatsby";

import { Seo, Articles } from "../components";
import Layout from "../components/Layout";

const News = ({ data }) => {
  const news = data.news.nodes?.map(node => {
    return node.data;
  });

  return (
    <Layout>
      <Seo title="News" />
      <Articles data={news} />
    </Layout>
  );
};

export const query = graphql`
  {
    news: allAirtable(
      filter: { table: { eq: "news" } }
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        data {
          title
          date
          excerpt
          link
          image {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  }
`;

export default News;
